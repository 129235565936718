<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="userEventsTable"
        v-model="selected"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="custom-data-table"
        dense
        must-sort
        :loading="loading"
        loading-text="Loading..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        :server-items-length="total"
        item-key="createdAt"
        fixed-header
        :footer-props="{
          'items-per-page-options': [100, 500, 1000],
        }"
        :hide-default-footer="items.length <= 100"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="pl-0 pb-0 text-h6"> User Events </ECol>
              <v-spacer />
              <ECol cols="1" class="mb-1 pb-0 text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item.createdAt="{ item }">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ relativeDate(item.createdAt) }}
              </span>
            </template>
            <span>
              {{ formatDate(item.createdAt) }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import usersEventsTableHeader from "@/components/users/usersEventsTableHeaders"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  components: { CopyTableToClipboard },
  props: {
    userId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: [],
      options: {},
      total: 0,
      loading: true,
      sortBy: "name",
      sortDesc: true,
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.$refs.userEventsTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    userId() {
      this.fetchUserEvents()
    },
  },
  async mounted() {
    this.headers = usersEventsTableHeader(this)
    await this.fetchUserEvents()
  },
  methods: {
    async fetchUserEvents() {
      if (!this.userId) {
        return
      }
      try {
        this.loading = true
        const response = await AdminApi.pa.getPAUserEvents(this.userId)
        this.loading = false
        this.total = response.total

        response.data.forEach((element) => {
          element.name = this.toTitleCase(element.name)
        })

        this.items = response.data
      } catch (error) {
        this.$notifications.error({
          text: "Could not load User Events!",
          error,
        })
      }
    },
  },
}
</script>
