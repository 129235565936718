<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="projectsTable"
        v-model="selected"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="custom-data-table"
        dense
        must-sort
        :loading="loading"
        loading-text="Loading..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        :server-items-length="total"
        item-key="id"
        fixed-header
        :footer-props="{
          'items-per-page-options': [50, 100, 500],
        }"
        :hide-default-footer="items.length <= 50"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="pl-0 pb-0 text-h6"> Projects </ECol>
              <v-spacer />
              <ECol cols="1" class="mb-1 pb-0 text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item.startedAt="{ item }">
          {{ formatDate(item.startedAt) }}
        </template>
        <template #item.name="{ item }">
          <span
            class="cursor-pointer primary--text"
            @click="openProjectDialog(item)"
          >
            {{ item.name }}
          </span>
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import usersProjectsTableHeader from "@/components/users/usersProjectsTableHeaders"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"

export default {
  components: {
    CopyTableToClipboard,
  },
  props: {
    userId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: [],
      options: {},
      total: 0,
      loading: true,
      sortBy: "name",
      sortDesc: true,
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    filteredItems() {
      let filteredItems = this.$refs.projectsTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    userId() {
      this.fetchProjects()
    },
  },
  async mounted() {
    this.headers = usersProjectsTableHeader(this)
    await this.fetchProjects()
  },
  methods: {
    async fetchProjects() {
      if (!this.userId) {
        return
      }
      try {
        this.loading = true
        const response = await AdminApi.pa.getPAUserProjects(this.userId)
        this.total = response.total
        this.items = response.data
      } catch (error) {
        this.$notifications.error({ text: "Could not load Project", error })
      } finally {
        this.loading = false
      }
    },
    openProjectDialog(project) {
      this.projectStore.openDialog(project.exid)
    },
  },
}
</script>
