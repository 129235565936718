<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="userCamerasTable"
        v-model="selected"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="custom-data-table"
        dense
        must-sort
        :loading="loading"
        loading-text="Loading..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        :server-items-length="total"
        item-key="id"
        fixed-header
        :footer-props="{
          'items-per-page-options': [50, 100, 500],
        }"
        :hide-default-footer="items.length <= 50"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="pl-0 pb-0 text-h6"> Cameras </ECol>
              <v-spacer />
              <ECol cols="1" class="mb-1 pb-0 text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item.name="{ item }">
          <a @click="cameraDialogStore.openDialog({ camera: item.exid })">{{
            item.name
          }}</a>
          <ImpersonateUserButton
            v-if="allowImpersonations"
            :email="item.ownerEmail"
            :target-route="`/projects/${item.projectId}/${item.exid}`"
          />
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import PaUsersCamerasTableHeader from "@/components/users/usersCamerasTableHeaders"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"

export default {
  components: {
    CopyTableToClipboard,
    ImpersonateUserButton,
  },
  props: {
    userId: {
      type: [Number, undefined],
      default: undefined,
    },
    allowImpersonations: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: PaUsersCamerasTableHeader,
      options: {},
      total: 0,
      loading: true,
      sortBy: "name",
      sortDesc: true,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    filteredItems() {
      let filteredItems = this.$refs.userCamerasTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    userId() {
      this.fetchCameras()
    },
  },
  async mounted() {
    await this.fetchCameras()
  },
  methods: {
    async fetchCameras() {
      if (!this.userId) {
        return
      }
      try {
        this.loading = true
        const response = await AdminApi.pa.getPAUserCameras(this.userId)
        this.loading = false
        this.total = response.total
        this.items = response.data
      } catch (error) {
        this.$notifications.error({ text: "Could not load Cameras!", error })
      }
    },
  },
}
</script>
